import {Button, styled} from '@mui/material';

const StyledButton = styled(Button)(({theme}) => ({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 20,
    padding: '12px 12px',
    lineHeight: 1,
    backgroundColor: theme.basic.gold,
    borderColor: theme.basic.white,
    color: theme.basic.black,
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: theme.basic.gold,
        borderColor: theme.basic.white,
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: theme.basic.gold,
        borderColor: theme.basic.white,
    },
    '&:focus': {
        boxShadow: 'none',
        borderColor: theme.basic.white,
    },
}));
export default StyledButton;