import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import DiagnosticPage from './diagnosticPage';
import {createTheme, ThemeProvider} from "@mui/material";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


declare module '@mui/material/styles' {
    interface Theme {
        basic: {
            black?: string;
            gold?: string;
            white?: string;

        };
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        basic?: {
            black?: string;
            gold?: string;
            white?: string;
        };
    }
}
const theme = createTheme({
    basic: {
        black: '#000000',
        gold: '#cccccc',
        white: '#000000',
    },
});


root.render(
    <ThemeProvider theme={theme}>
        <DiagnosticPage/>
    </ThemeProvider>
);
