import {BASE_API_PATH, INIT_DATA} from '../config';
import {DiagnosticResponseI, OptionsRequestI} from "../types/types";

export function getDiagnostic(testId: number): Promise<DiagnosticResponseI[]> {
    // if (!process.env.REACT_APP_BASE_URL) throw new Error('REACT_APP_BASE_URL does not exist');

    return fetch(`${BASE_API_PATH}/lesson/${testId}/questions`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-TG-INIT-DATA': INIT_DATA,
        },
    })
        .then(resp => resp.json());
}

export function postDiagnostic(body: OptionsRequestI, testId: number) {
    // if (!process.env.REACT_APP_BASE_URL) throw new Error('REACT_APP_BASE_URL does not exist');

    return fetch(`${BASE_API_PATH}/lesson/${testId}/answers`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-TG-INIT-DATA': INIT_DATA,
        },
        body: JSON.stringify(body),
    })
        .then(resp => resp?.json())
        .catch(err => console.error(err));
}