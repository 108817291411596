import React, {useEffect, useState} from "react";
import styles from './diagnostic-page.module.css';
import StyledButton from "./components/common/styledButton/styledButton";
import {CheckboxBlock} from "./components";
import {getDiagnostic, postDiagnostic} from "./api/diagnostic";
import {DiagnosticOptionsI, DiagnosticResponseI} from "./types/types";
import {OptionsAnswerModel} from "./types/models";
import {validateOrReject} from "class-validator";
import {closeWebapp, showWebappAlert} from "./utils";
import {ERROR_MESSAGE, SUCCESS_MESSAGE} from "./config";
import logoImg from './images/logo.svg';

export default function DiagnosticPage() {
    const searchParams = new URLSearchParams(document.location.search)
    const testId = searchParams.get('test_id')
    const [questions, setQuestions] = useState<DiagnosticResponseI[]>([])
    const [checkedOptions, setCheckedOptions] = useState<DiagnosticOptionsI[]>([]);

    useEffect(() => {
        if(!testId) throw new Error('test_uuid not found')
        void getDiagnostic(Number(testId)).then(diagnostic => setQuestions(diagnostic))
    }, []);

    function handleFormChanges(checked: boolean, diagnosticOption?: DiagnosticOptionsI): void {
        if (!diagnosticOption) return;
        setCheckedOptions(prevState => {
            const newState = [...prevState.filter(option => diagnosticOption.question_id !== option.question_id)]
            if (checked) newState.push(diagnosticOption);
            return newState;
        });
    }

    function handleFormChangesMultiple(checked: boolean, diagnosticOption?: DiagnosticOptionsI): void {
        if (checked && diagnosticOption) {
            setCheckedOptions(prevState => [...prevState, diagnosticOption])
        } else {
            setCheckedOptions(prevState => prevState.filter(
                option => diagnosticOption?.id !== option.id
            ))
        }
    }

    function isButtonDisabled(questions: DiagnosticResponseI[], checkedOptions: DiagnosticOptionsI[]): boolean {
        return questions?.reduce((acc, question) => {
            if (!checkedOptions.map(option => option.question_id).includes(question.id)) return true
            return acc;
        }, false);
    }

    function sendFormData(): void {
        const answers = checkedOptions?.map(option => option.id);
        const configuration = new OptionsAnswerModel();
        configuration.answers = answers;

        validateOrReject(
            configuration,
        ).then(() => {
            if(!testId) throw new Error('test_id not found')
            return void postDiagnostic({answers}, Number(testId))
        }).then(() => {
            closeWebapp();
            showWebappAlert(SUCCESS_MESSAGE, () => closeWebapp());
        }).catch(err => {
            showWebappAlert(ERROR_MESSAGE, () => closeWebapp());
            console.error(err);
        })
    }

    return (
        <div className={styles.wrapper}>
            <img className={styles.logo} src={logoImg} alt="logo"/>
            {questions?.map((question, key) => (
                <CheckboxBlock
                    singleSelection={!question.is_multiple_answers}
                    key={`${question.text}_${key}`}
                    header={question.text}
                    questions={question.answer_options?.map(option => ({text: option.text, data: option}))}
                    onChange={(checked, value) => {
                        if (question.is_multiple_answers) {
                            handleFormChangesMultiple(checked, value);
                        } else {
                            handleFormChanges(checked, value);
                        }
                    }}
                />
            ))}
            <StyledButton
                disabled={isButtonDisabled(questions, checkedOptions)}
                onClick={sendFormData}
            >
                Отправить
            </StyledButton>
        </div>
    );
}
