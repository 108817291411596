export function closeWebapp() {
    setTimeout(() => {
        // @ts-ignore
        window.Telegram.WebApp.close();
    }, 2000)
}

export function showWebappAlert(message: string, callback?: () => void) {
    // @ts-ignore
    window.Telegram.WebApp.showAlert(message, callback);
}