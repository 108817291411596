import React from "react";
import {Checkbox, CheckboxProps, useTheme} from "@mui/material";

export default function StyledCheckbox(props: CheckboxProps) {
    const theme = useTheme();

    return (
        <Checkbox
            {...props}
            sx={{
                color: theme.basic.gold,
                '&.Mui-checked': {
                    color: theme.basic.gold,
                },
            }}
        />
    );
}