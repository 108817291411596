import React, {useState} from "react";
import StyledCard from "../common/styledCard/styledCard";
import StyledCheckbox from "../common/styledCheckbox/styledCheckbox";
import AdjustRoundedIcon from '@mui/icons-material/AdjustRounded';
import PanoramaFishEyeRoundedIcon from '@mui/icons-material/PanoramaFishEyeRounded';

export interface QuestionI<T> {
    text: string;
    right?: boolean;
    data?: T;
}

export interface CheckboxBlockPropsI<T> {
    header?: string;
    questions?: QuestionI<T>[];
    onChange?: (checked: boolean, value?: T) => void;
    singleSelection?: boolean;
}

export default function CheckboxBlock<T>(
    {
        header,
        onChange,
        questions = [],
        singleSelection = false
    }: CheckboxBlockPropsI<T>
) {
    const [checkedQuestions, setCheckedQuestions] = useState<number[]>([]);

    function checkboxHandler(index: number, checked: boolean): void {
        onChange?.(checked, questions?.[index]?.data);

        if (singleSelection) {
            setCheckedQuestions(checked ? [index] : []);
            return;
        }

        if (checked) {
            setCheckedQuestions(prevState => [...prevState, index]);
        } else {
            setCheckedQuestions(prevState => prevState.filter(value => value !== index));
        }
    }

    return (
        <StyledCard>
            <div>
                <h3>{header}</h3>
                {questions?.map((question, index) =>
                    <div key={`${question.text}_${index}`}>
                        <StyledCheckbox
                            checkedIcon={singleSelection ? <AdjustRoundedIcon/> : undefined}
                            icon={singleSelection ? <PanoramaFishEyeRoundedIcon/> : undefined}
                            checked={checkedQuestions.includes(index)}
                            onChange={(_, checked) => checkboxHandler(index, checked)}
                        />
                        <span>{question.text}</span>
                    </div>
                )}
            </div>
        </StyledCard>
    );
}